<script>

import AdvancedTable from "../../../components/elements/advanced-table.vue";
import {mapGetters} from "vuex";
import FilePrivateViewer from "../../../components/elements/file -private-viewer.vue";

export default {
    components: {
        FilePrivateViewer,
        AdvancedTable,
    },

    computed: {
        ...mapGetters({
            locale: 'trans/locale'
        }),

        fields: function () {
            return [
                {
                    key: "code",
                    label: this.$t('projects::contracts.columns.code').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "project",
                    label: this.$t('projects::contracts.relationships.project').ucFirst(),
                    formatter: (value, index, item) => item.project?.translation_key ? this.$t(item.project.translation_key).ucFirst() : item.project?.name,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "title",
                    label: this.$t('projects::contracts.columns.title').ucFirst(),
                    formatter: (value, index, item) => item?.translation_key ? this.$t(item.translation_key).ucFirst() : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "contract_type",
                    label: this.$t('projects::contracts.relationships.contract_type').ucFirst(),
                    formatter: (value, index, item) => item.contract_type?.translation_key ? this.$t(item.contract_type.translation_key).ucFirst() : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "file",
                    label: this.$t('projects::contracts.relationships.file').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "signed_file",
                    label: this.$t('projects::contracts.relationships.signed_file').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "deadline",
                    label: this.$t('projects::contracts.columns.deadline').ucFirst(),
                    formatter: (value) => value && value.printDate(this.locale),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "validity",
                    label: this.$t('projects::contracts.validity').ucFirst(),
                    formatter: (value, index, item) => [item.start?.printDate(this.locale), item.end?.printDate(this.locale)].filter(str => str)?.join(' - '),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "sent_at",
                    label: this.$t('projects::contracts.columns.sent_at').ucFirst(),
                    formatter: (value) => value?.printDate(this.locale),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                },
            ];
        },
    },

    data() {
        return {};
    },

    methods: {
        getDate: function (str) {
            return str.split(',')[0]
        },

        getTime: function (str) {
            return str.split(',')[1]
        },
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'projects::contracts.contract', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }

    // :create-link="{ name: 'my-contract-create' }"
};
</script>

<template>
    <advanced-table
        :caption="$tc('projects::contracts.contract', 2).ucFirst()"
        :current-page="1"
        :edit-link="{ name: 'my-contract-edit' }"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :queries="{ with: ['file', 'signedFile', 'project', 'contractType'] }"
        :per-page="10"
        borderless
        deletable
        editable
        hover
        moduleNamespace="contract"
        action="own"
        outlined
        permission="contracts"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="id"
        sort-direction="asc"
        sort-icon-left
    >
        <template #file="{ value, item }">
            <template v-if="value">
                <file-private-viewer model="contract" :id="item.id" :file-id="item.file_id">
                    <template #default="{ click }">
                        <i class="fas fa-file-pdf text-primary" @click="click"></i>
                    </template>
                </file-private-viewer>
            </template>
        </template>
        <template #signed_file="{ value, item }">
            <template v-if="value">
                <file-private-viewer model="contract" :id="item.id" :file-id="item.signed_file_id">
                    <template #default="{ click }">
                        <i class="fas fa-file-pdf text-primary" @click="click"></i>
                    </template>
                </file-private-viewer>
            </template>
        </template>
    </advanced-table>
</template>
